<template>
	<div class="container" v-loading="isLoading">
		<label-item title="账单信息">
			<template #subTitle>
				<span class="click" @click="onViewBil">查看全部</span>
			</template>
		</label-item>
		<yi-table table-height="100%" :columns="columns" :data="tableData">
			<template #status="{ value }">
				<span>{{ mapDict('trade_bill_status', value) }}</span>
			</template>
			<template #tradeBillType="{ value }">
				<span>{{ mapDict('trade_bill_type', value) }}</span>
			</template>
			<template #statementDate="{ value }">
				<span>{{ dayjs(value).format('YYYY-MM-DD') }}</span>
			</template>
		</yi-table>
	</div>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineProps, defineExpose, inject, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import labelItem from './label'
import { useState, useFetch, useDict } from '@/utils/hooks.js'
import { billDetail } from '@/api/driver'

const _ = defineProps({
	data: Object,
})
const $message = inject('$message')

const [tableData, setTable] = useState([])
const [dict, setDict] = useState({})
const { isLoading, fetchData } = useFetch()
const router = useRouter()
const columns = [
	{
		prop: 'tradeBillType',
		label: '账单类型',
		width: '120px',
	},
	{
		prop: 'status',
		label: '账单状态',
		width: '120px',
	},
	{
		prop: 'statementDate',
		label: '账单日期',
		width: '120px',
	},
	{
		prop: 'statementPeriod',
		label: '账单周期',
		width: '220px',
	},
	{
		prop: 'tradeAmount',
		label: '总应收额',
		width: '120px',
	},
	{
		prop: 'actualAmount',
		label: '总实收额',
		width: '120px',
	},
	{
		prop: 'deductionAmount',
		label: '抵扣金额',
		width: '120px',
	},
]

function onViewBil() {
	router.push({ path: '/driver-rent', query: { keyword: _.data.name } })
}

async function fetchList() {
	const { err, data } = await fetchData(billDetail, {
		driverId: _.data.driverId,
	})
	if (err) $message.error(err.message)
	setTable(data)
}
async function fetchDict() {
	const dict = await useDict(['trade_bill_type', 'trade_bill_status'])
	console.log('dict', dict)
	if (dict) setDict(dict)
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)
	return target ? target.name : '--'
}
function init() {
	fetchDict()
	fetchList()
}
onMounted(() => {
	init()
})
defineExpose({ init })
</script>

<style lang="less" scoped>
.container {
	.click {
		font-size: 14px;
		color: #2d8cf0;
		cursor: pointer;
		margin-left: 12px;
	}
	.viewer {
		display: none;
	}
}
</style>
