<template>
	<el-dialog
		v-model="visible"
		width="750px"
		class="container"
		:title="title"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-if="visible" v-loading="isLoading">
			<yi-form
				v-if="visible"
				:model="model"
				:rules="rules"
				ref="formRef"
				label-width="120px"
				size="small"
				:form-list="formList"
			>
				<template #birthday>
					<el-date-picker
						placeholder="上传身份证自动填写"
						type="date"
						size="default"
						style="width: 190px"
						v-model="model.birthday"
						:disabled="disabled"
					/>
				</template>
				<template #contactPersonName>
					<div class="row">
						<el-select v-model="model.contactPersonType" size="default">
							<el-option
								v-for="item in relation"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
						<el-input
							placeholder="输入联系人姓名"
							size="default"
							style="margin-left: 20px; width: 290px"
							v-model="model.contactPersonName"
						/>
					</div>
				</template>
				<template #drivingLicenseStartDate>
					<el-date-picker
						placeholder="请选择开始日期"
						type="date"
						size="default"
						style="width: 190px"
						v-model="model.drivingLicenseStartDate"
					/>
				</template>
				<template #drivingLicenseEndDate>
					<el-date-picker
						placeholder="请选择结束日期"
						type="date"
						size="default"
						style="width: 190px"
						v-model="model.drivingLicenseEndDate"
					/>
				</template>
			</yi-form>
			<p class="title">司机证件</p>
			<yi-form
				:model="model"
				:rules="rules"
				ref="uploadRef"
				label-width="120px"
				size="small"
				:form-list="uploadForm"
			>
				<template #idCardReversePic>
					<div class="img-box">
						<div>
							<yi-file-uploader
								v-model="model.idCardReversePic"
								type="image"
								@update:modelValue="onPicChange('idCardReversePic', $event)"
								source-type="image"
								product-type="renrenjia"
								accept=".jpg,.jpeg,.png"
								:max="1"
								:uploaderSize="20"
							/>
							<p>上传身份证人像面</p>
						</div>
					</div>
				</template>
				<template #idCardObversePic>
					<yi-file-uploader
						v-model="model.idCardObversePic"
						type="image"
						@update:modelValue="onPicChange('idCardObversePic', $event)"
						source-type="image"
						product-type="renrenjia"
						accept=".jpg,.jpeg,.png"
						:max="1"
						:uploaderSize="20"
					/>
					<p>上传身份证国徽面</p>
				</template>
			</yi-form>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, ref, inject, defineEmits } from 'vue'
import { useState, useModal, useFetch, useSelect, useDict } from '@/utils/hooks.js'
import { telephoneAndMobileReg, idCardReg } from '@/utils/regUtil.js'
import { companySelect } from '@/api/vehicle'
import { ocrIDCard, createDriver, editDriver, driverDetail } from '@/api/driver'
import { form, uploadForm } from './config'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const formList = ref(form)

const { visible, title, setVisible, setTitle } = useModal()
const [model, setModel] = useState({
	drivingLicensePic: [],
	certificateProfessionalPic: [],
})
const [disabled, setDisabled] = useState(false)
const [relation, setRelation] = useState([])
const { isLoading, fetchData } = useFetch()

const startDate = (_, val, callback) => {
	const { drivingLicenseEndDate } = model.value
	if (!drivingLicenseEndDate) return callback()
	if (
		val &&
		(dayjs(drivingLicenseEndDate).isBefore(val) || dayjs(drivingLicenseEndDate).isSame(val))
	) {
		callback(new Error('开始日期不能晚于或等于结束日期'))
	} else {
		callback()
	}
}

const endDate = (_, val, callback) => {
	const { drivingLicenseStartDate } = model.value
	if (!drivingLicenseStartDate) return callback()
	if (
		val &&
		(dayjs(val).isBefore(drivingLicenseStartDate) || dayjs(val).isSame(drivingLicenseStartDate))
	) {
		callback(new Error('结束日期不能早于或等于开始日期'))
	} else {
		callback()
	}
}

const rules = {
	mobile: [{ pattern: telephoneAndMobileReg, message: '请输入正确格式手机号', trigger: 'blur' }],
	idCard: [{ pattern: idCardReg, message: '请输入正确格式身份证', trigger: 'blur' }],
	contactPersonMobile: [
		{ pattern: telephoneAndMobileReg, message: '请输入正确格式手机号', trigger: 'blur' },
	],
	drivingLicenseStartDate: [{ validator: startDate }],
	drivingLicenseEndDate: [{ validator: endDate }],
}
const formRef = ref(null)
const uploadRef = ref(null)

// 触发身份证orc识别
function onPicChange(key, val) {
	const data = val[0]
	if (key === 'idCardReversePic') {
		if (data && !title.value.includes('编辑')) {
			recognize(data)
		}
	}
}
async function recognize(url) {
	const { err, data } = await fetchData(ocrIDCard, { url })
	if (err) {
		$message.error('识别失败')
		const res = {
			name: '',
			idCard: '',
			sex: '',
			birthday: '',
			idCardAddress: '',
		}
		setModel(Object.assign(model.value, res))
	} else {
		setModel(Object.assign(model.value, data))
	}
}

function open(data) {
	fetchList()
	fetchDict()
	if (data) {
		setTitle('编辑司机')
		setDisabled(true)

		fetchDetail(data.driverId)
	} else {
		setTitle('新增司机')
		setDisabled(false)
	}
	const valid = [
		'mobile',
		'driverNo',
		'presentAddress',
		'contactPersonName',
		'contactPersonMobile',
		'drivingLicenseDate',
		'drivingLicensePic',
	]
	formList.value.forEach(item => {
		if (item.attrs) item.attrs.disabled = data ? !valid.find(key => key === item.prop) : false
		else {
			item.attrs = {}
			item.attrs.disabled = data ? !valid.find(key => key === item.prop) : false
		}
	})
	const companyId = formList.value.find(item => item.prop === 'companyId')
	const mobile = findItem('mobile')
	mobile.attrs.disabled = !!data
	const { status } = data || {}
	companyId.attrs.disabled = status ? (status == 2 ? true : false) : false
	console.log('data', data)
	setVisible(true)
}

async function fetchDetail(id) {
	const { err, data } = await fetchData(driverDetail, id)
	if (err) return $message.error(err.msg || err.message)
	const {
		drivingLicenseDate,
		idCardReversePic,
		idCardObversePic,
		drivingLicensePic,
		certificateProfessionalPic,
	} = data
	data.idCardReversePic = [idCardReversePic]
	data.idCardObversePic = [idCardObversePic]
	data.drivingLicensePic = drivingLicensePic || []
	data.certificateProfessionalPic = certificateProfessionalPic || []
	const [start, end] = drivingLicenseDate
	data.drivingLicenseStartDate = start
	data.drivingLicenseEndDate = end
	setModel(Object.assign(model.value, data))
}

function onClose() {
	setVisible(false)
	setModel({})
}
async function onSubmit() {
	await Promise.all([formRef.value.validate(), uploadRef.value.validate()])

	try {
		const params = { ...model.value }
		const { drivingLicenseStartDate: start, drivingLicenseEndDate: end } = params
		params.drivingLicenseStartDate = dayjs(start).format('YYYY-MM-DD') + ' 00:00:00'
		params.drivingLicenseEndDate = dayjs(end).format('YYYY-MM-DD') + ' 23:59:59'
		params.birthday = dayjs(params.birthday).format('YYYY-MM-DD HH:mm:ss')
		params.idCardObversePic = params.idCardObversePic[0]
		params.idCardReversePic = params.idCardReversePic[0]
		const service = title.value.includes('编辑') ? editDriver : createDriver
		const res = await service(params)
		if (res.code !== 200) throw new Error(res.msg || res.message)
		$message.success(title.value.includes('编辑') ? '编辑成功' : '新建成功')
		emit('on-refresh')
		onClose()
	} catch (e) {
		console.log('e', e)
		$message.error(e.message)
	}
}
function findItem(key) {
	return formList.value.find(item => item.prop === key)
}
async function fetchList() {
	const { data } = await useSelect(() => companySelect(true), {
		name: 'companyName',
		code: 'companyId',
	})
	const companyId = findItem('companyId')
	companyId.options = data
	if (data.length === 1) {
		model.value.companyId = data[0].value
	}
}
async function fetchDict() {
	const dict = await useDict(['contact_person_type', 'sex'])
	const sex = findItem('sex')
	sex.options = dict.sex.map(item => ({ value: item.code * 1, label: item.name }))
	setRelation(dict.contact_person_type.map(item => ({ value: item.code * 1, label: item.name })))
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.img-box {
	.flexible(row,center, flex-start);
}
.row {
	.flexible(row, center, flex-start);
}
</style>
