<template>
	<el-drawer
		class="container"
		v-model="visible"
		:append-to-body="false"
		:show-close="false"
		size="1100"
		:with-header="false"
	>
		<div v-loading="isLoading" class="detail-container">
			<div class="header">
				<div>
					<p class="title">
						司机：{{ detail.name }} （{{ mapStatus(detail.status, 'driver_status') }}）
					</p>
					<div class="top-container-main">
						<span class="label">
							所属公司：
							<span class="value">{{ detail.companyName }}</span>
						</span>
						<span class="label">
							创建时间：
							<span class="value">{{ detail.createTime }}</span>
						</span>
						<span class="label">
							最后更新时间：
							<span class="value">{{ detail.updateTime }}</span>
						</span>
					</div>
				</div>

				<el-icon size="28" color="#707070" style="cursor: pointer" @click="onClose">
					<CircleClose />
				</el-icon>
			</div>

			<div class="main-container">
				<div class="left-container">
					<div class="button-list">
						<el-button class="button" @click="onEdit">编辑</el-button>
					</div>
					<div class="left-label">司机姓名</div>
					<div class="staff">
						<div class="avatar" v-if="detail.name">
							<p>
								{{ formatName(detail.name) }}
							</p>
						</div>
						<p>{{ detail.name }}</p>
						<p class="staff-phone">{{ detail.mobile }}</p>
					</div>
					<div class="left-label">司服伙伴</div>
					<div class="staff">
						<div class="avatar" v-if="detail.driverSupportName">
							<p>
								{{ formatName(detail.driverSupportName) }}
							</p>
						</div>
						<p>{{ detail.driverSupportName }}</p>
						<p class="staff-phone">{{ detail.driverSupportMobile }}</p>
					</div>
				</div>
				<div class="tab-container">
					<el-tabs v-model="activeTab" class="tab-container-self" @tab-change="handleTabClick">
						<el-tab-pane name="driverTab" label="司机信息">
							<DriverTab :data="detail" v-if="activeTab === 'driverTab'" />
						</el-tab-pane>
						<el-tab-pane name="contractTab" label="合同信息">
							<ContractTab :data="detail" v-if="activeTab === 'contractTab'" />
						</el-tab-pane>
						<el-tab-pane name="billTab" label="账单信息">
							<BillTab :data="detail" v-if="activeTab === 'billTab'" />
						</el-tab-pane>

						<el-tab-pane name="violationTab" :label="`违章信息(${detail.carViolateNum || 0})`">
							<ViolationTab :data="detail" v-if="activeTab === 'violationTab'" />
						</el-tab-pane>
						<el-tab-pane name="operationTab" label="操作记录">
							<OperationTab :data="detail" v-if="activeTab === 'operationTab'" />
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<edit-form ref="formRef" @on-refresh="refreshDetail" />
		</div>
	</el-drawer>
</template>

<script setup>
import { defineExpose, inject, ref } from 'vue'
import { useState, useFetch, useDict } from '@/utils/hooks.js'
import { driverDetail } from '@/api/driver'
import DriverTab from './driverTab'
import ContractTab from './contractTab'
import BillTab from './billTab'
import ViolationTab from './violationTab.vue'
import OperationTab from './operationTab.vue'

// import ManagementForm from '../components/form'
import EditForm from '../form'
// import BatchRenewal from '../renewal'

const $message = inject('$message')

const [visible, setVisible] = useState(false)
const [activeTab, setTab] = useState('vehicle')
const [dict, setDict] = useState({})
const [detail, setDetailData] = useState({})
const [detailId, setId] = useState('')
const { isLoading, fetchData } = useFetch()

const formRef = ref(null)

function onEdit() {
	formRef.value.open(detail.value)
}
function onClose() {
	setVisible(false)
	setDetailData({})
	setId('')
}
function formatName(name) {
	return name.substring(name.length - 2, name.length)
}
async function fetchDetail(id) {
	const { data, err } = await fetchData(driverDetail, id)
	if (err) return $message.error(err.message)
	setDetailData(data)
	const statusName = mapStatus(data.status, 'driver_status')
	detail.value.statusName = statusName
	const sexName = mapStatus(data.sex, 'sex')
	detail.value.sexName = sexName
	setId(data.driverId)
}
function refreshDetail() {
	fetchDetail(detailId.value)
}
function mapStatus(value, type) {
	const target = dict.value[type].find(item => item.code == value)
	if (!target) return '--'
	return target.name
}
function handleTabClick(val) {
	setTab(val)
	// switch (val) {
	// 	case 'driverTab':
	// 		driverTab.value && driverTab.value.init()
	// 		break
	// 	case 'contractTab':
	// 		contractTab.value && contractTab.value.init()
	// 		break
	// 	case 'billTab':
	// 		billTab.value && billTab.value.init()
	// 		break
	// 	case 'violationTab':
	// 		violationTab.value && violationTab.value.init()
	// 		break
	// 	case 'operationTab':
	// 		operationTab.value && operationTab.value.init()
	// 		break
	// 	default:
	// 		break
	// }
}
async function fetchDict() {
	const dict = await useDict(['driver_status', 'sex', 'contact_person_type'])
	if (dict) setDict(dict)
}

async function open(id) {
	await fetchDict()
	fetchDetail(id)
	setTab('driverTab')
	setVisible(true)
}
defineExpose({ open })
</script>
<style lang="less">
.container > .el-drawer__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
.detail-container {
	width: 100%;
	height: 100%;
	.flexible(column, flex-start, flex-start);
	overflow: hidden;
}
.header {
	padding: 5px 0 10px 24px;
	background-color: #fff;
	.flexible(row,center, space-between);
	width: 1050px;
	.title {
		font-size: 18px;
		margin-bottom: 8px;
	}
	.extraInfo {
		color: #cfcfcf;
	}
	.top-container-main {
		display: flex;
		align-items: center;
		padding-right: 24px;
		.top-container-main-right {
			.button {
				margin-right: 10px;
			}
		}
		.label {
			font-size: 12px;
			color: #707070;
			margin-right: 10px;
		}
		.value {
			font-size: 12px;
			color: rgba(0, 0, 0.84);
			&.click-tip {
				color: #409eff;
			}
		}
	}
}
.main-container {
	flex: 1;
	.flexible(row, flex-start, flex-start);
	border-top: 7px solid #f0f2f5;
	width: 100%;
	height: 0;
	.left-container {
		width: 338px;
		height: 100%;
		padding: 16px;
		overflow-y: auto;
		.button-list {
			.flexible(row, center, flex-start);
			flex-wrap: wrap;
			.button {
				width: 120px;
				height: 36px;
				border: 1px solid #dcdfe6;
				border-radius: 4px;
				.flexible(row, center, center);
				color: #606266;
				margin: 0 20px 20px 20px;
			}
		}
		.left-label {
			border-left: 3px solid #2e3443;
			padding-left: 4px;
			line-height: 15px;
			font-size: 16px;
			margin: 10px 0;
		}
		.staff {
			.flexible(row, center, flex-start);
			margin-right: 20px;
			.staff-phone {
				margin-left: 20px;
				font-size: 14px;
			}
		}
		.avatar {
			height: 30px;
			width: 30px;
			border-radius: 15px;
			margin-right: 20px;
			.flexible(row, center, center);
			background-color: #409eff;
			color: white;
			text-align: center;
			font-size: 14px;
		}
	}
	.tab-container {
		border-left: 7px solid #f0f2f5;
		padding: 8px 20px;
		flex: 1;
		height: 100%;
		width: 0;
		overflow: auto;
	}
	.tab-container::-webkit-scrollbar {
		width: 0 !important;
	}
}
</style>
