export const signingColumns = [
	{
		prop: 'status',
		label: '司机状态',
		width: '120px',
	},
	{
		prop: 'orderStatus',
		label: '合同状态',
		width: '120px',
	},
	{
		prop: 'name',
		label: '司机姓名',
		width: '140px',
	},
	{
		prop: 'sex',
		label: '性别',
	},

	{
		prop: 'age',
		label: '年龄',
	},
	{
		prop: 'mobile',
		label: '手机号',
		width: '180px',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '120px',
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		width: '120px',
	},
	{
		prop: 'drivingLicenseEndDate',
		label: '驾驶证截至日期',
		width: '160px',
	},
	{
		prop: 'companyName',
		label: '所属公司',
		width: '280px',
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: '200px',
	},
	{
		prop: 'operation',
		label: '操作',
		fixed: 'right',
		width: '140px',
	},
]
export const registerColumns = [
	{
		prop: 'status',
		label: '司机状态',
		width: '120px',
	},
	{
		prop: 'name',
		label: '司机姓名',
		width: '140px',
	},
	{
		prop: 'sex',
		label: '性别',
		width: '140px',
	},

	{
		prop: 'age',
		label: '年龄',
		width: '140px',
	},
	{
		prop: 'mobile',
		label: '手机号',
		width: '180px',
	},
	// {
	// 	prop: 'carNumber',
	// 	label: '车牌号',
	// 	width: '120px',
	// },
	// {
	// 	prop: 'driverSupportName',
	// 	label: '司服伙伴',
	// 	width: '120px',
	// },
	// {
	// 	prop: 'drivingLicenseEndDate',
	// 	label: '驾驶证截至日期',
	// 	width: '160px',
	// },
	{
		prop: 'companyName',
		label: '所属公司',
		minWidth: '280px',
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: '200px',
	},
	{
		prop: 'operation',
		label: '操作',
		fixed: 'right',
		width: '80px',
	},
]
export const signingSetting = {
	expand: true,
	filters: [
		{
			placeholder: '输入司机姓名手机号或身份证进行搜索',
			label: '司机信息',
			attr: 'keyword',
			type: 'search',
			width: 300,
		},
		{
			placeholder: '输入车辆车牌',
			label: '车辆车牌',
			attr: 'carNumber',
			type: 'search',
		},
		{
			placeholder: '请选择司机状态',
			label: '司机状态',
			attr: 'status',
			type: 'multi-select',
			clearable: false,
			options: [],
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyId',
			type: 'multi-select',
			width: 320,
			options: [],
		},
	],
}
export const registerSetting = {
	expand: false,
	filters: [
		{
			placeholder: '输入司机姓名手机号或身份证进行搜索',
			label: '司机信息',
			attr: 'keyword',
			type: 'search',
			width: 300,
		},
		{
			placeholder: '请选择司机状态',
			label: '司机状态',
			attr: 'status',
			type: 'multi-select',
			clearable: false,
			options: [],
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyId',
			type: 'multi-select',
			width: 320,
			options: [],
		},
	],
}

export const form = [
	{
		prop: 'driverNo',
		label: '平台司机ID',
		component: 'el-input',
		attrs: { placeholder: '请输入司机ID', maxLength: 20 },
	},
	{
		prop: 'companyId',
		label: '所属公司',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择所属公司' },
	},
	{
		prop: 'name',
		label: '司机姓名',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '上传身份证自动填写', maxLength: 20 },
	},
	{
		prop: 'mobile',
		label: '手机号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机手机号', maxLength: 11 },
	},
	{
		prop: 'idCard',
		label: '身份证号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '上传身份证自动填写', maxLength: 18 },
	},
	{
		prop: 'sex',
		label: '性别',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '上传身份证自动填写' },
	},
	{
		prop: 'idCardAddress',
		label: '身份证地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '上传身份证自动填写', maxLength: 50 },
	},
	{
		prop: 'birthday',
		label: '出生日期',
		required: true,
		custom: true,
		attrs: { placeholder: '请选择出生日期' },
	},
	{
		prop: 'presentAddress',
		label: '现居住地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入现居住地址', maxLength: 50 },
	},
	{
		prop: 'contactPersonName',
		label: '紧急联系人',
		custom: true,
	},
	{
		prop: 'contactPersonMobile',
		label: '联系人手机',
		component: 'el-input',
		style: { 'margin-right': '200px' },
		attrs: { placeholder: '请输入联系人手机', maxLength: 11 },
	},
	{
		prop: 'drivingLicenseStartDate',
		label: '驾驶证开始日期',
		custom: true,
		required: true,
		attrs: { placeholder: '请选择驾驶证开始日期' },
	},
	{
		prop: 'drivingLicenseEndDate',
		label: '驾驶证结束日期',
		custom: true,
		required: true,
		attrs: { placeholder: '请选择驾驶证结束日期' },
	},
]

export const uploadForm = [
	{
		prop: 'idCardReversePic',
		required: true,
		label: '身份证',
		custom: true,
		attrs: { message: '请上传人像面' },
	},
	{
		prop: 'idCardObversePic',
		required: true,
		label: '',
		custom: true,
		attrs: { message: '请上传国徽面' },
	},
	{
		prop: 'drivingLicensePic',
		label: '驾驶证',
		component: 'yi-file-uploader',
		required: true,
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 2,
			message: '请上传驾驶证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'certificateProfessionalPic',
		label: '从业资格证',
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 2,
			message: '请上传从业资格证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
]
