import request from '@/http'

// 车辆管理列表
export function driverList(data) {
	return request({
		url: '/suf4-asset-service/driver/info/page',
		method: 'post',
		data,
	})
}
// 车辆管理导出
export function exportDriver(data) {
	return request({
		url: '/suf4-asset-service/driver/info/export',
		method: 'post',
		data,
		responseType: 'blob',
	})
}
// 新增车辆
export function createDriver(data) {
	return request({
		url: '/suf4-asset-service/driver/info/add',
		method: 'post',
		data,
	})
}
// 编辑车辆
export function editDriver(data) {
	return request({
		url: '/suf4-asset-service/driver/info/edit',
		method: 'post',
		data,
	})
}
// 车辆管理详情
export function driverDetail(id) {
	return request({
		url: '/suf4-asset-service/driver/info/detail?driverId=' + id,
		method: 'get',
	})
}

// 身份证OCR识别
export function ocrIDCard(data) {
	return request({
		url: '/suf4-asset-service/driver/info/ocr/idCard',
		method: 'post',
		data,
	})
}

// 订单分页
export function orderList(data) {
	return request({
		url: '/suf4-asset-service/order/info/page',
		method: 'post',
		data,
	})
}
// 新建订单
export function createOrder(data) {
	return request({
		url: '/suf4-asset-service/order/info/add',
		method: 'post',
		data,
	})
}
// 编辑订单
export function editOrder(data) {
	return request({
		url: '/suf4-asset-service/order/info/edit',
		method: 'post',
		data,
	})
}
// 合同终止
export function terminateOrder(data) {
	return request({
		url: '/suf4-asset-service/order/info/contract/terminate',
		method: 'post',
		data,
	})
}
// 合同续签
export function orderRenewal(data) {
	return request({
		url: '/suf4-asset-service/order/info/contract/renewal',
		method: 'post',
		data,
	})
}
// 合同续期
export function orderExtension(data) {
	return request({
		url: '/suf4-asset-service/order/info/contract/delay',
		method: 'post',
		data,
	})
}
// 合同列表统计
export function orderStatistics(data) {
	return request({
		url: '/suf4-asset-service/order/info/page/statistics',
		method: 'post',
		data,
	})
}
// 修改司服伙伴
export function modifyMaintainer(data) {
	return request({
		url: '/suf4-asset-service/order/info/edit/driver/support',
		method: 'post',
		data,
	})
}
// 导入订单
export function importOrder(data) {
	return request({
		url: '/suf4-asset-service/order/info/excel/import',
		method: 'post',
		data,
	})
}
// 导出订单
export function exportOrder(data) {
	return request({
		url: '/suf4-asset-service/order/info/excel/export',
		method: 'post',
		data,
		responseType: 'blob',
	})
}
// 订单详情
export function orderDetail(id) {
	return request({
		url: '/suf4-asset-service/order/info/summary/by/driver?driverId=' + id,
		method: 'get',
	})
}

// 新增合同时获取订单编号
export function getOrderNo(id) {
	return request({
		url: '/suf4-asset-service/order/info/get/insert/orderNo?companyId=' + id,
		method: 'get',
	})
}

// 司机下拉筛选
export function driverSelect({ companyId, status }) {
	return request({
		url: `/suf4-asset-service/driver/info/summary/by/company?companyId=${companyId}${
			status ? `&status=${status}` : ''
		}`,
		method: 'get',
	})
}

// 订单来源下拉筛选
export function orderSourceSelect() {
	return request({
		url: `/suf4-user-service/orderSource/select`,
		method: 'get',
	})
}

// 账单列表
export function billList(data) {
	return request({
		url: '/suf4-asset-service/order/trade/bill/page',
		method: 'post',
		data,
	})
}

// 更新账单状态
export function updateBill(data) {
	return request({
		url: '/suf4-asset-service/order/trade/bill/update/status',
		method: 'post',
		data,
	})
}

// 导出账单
export function exportBill(data) {
	return request({
		url: '/suf4-asset-service/order/trade/bill/export',
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 通过司机ID获取到相关账单概要信息
export function billDetail(params) {
	return request({
		url: '/suf4-asset-service/order/trade/bill/summary/by/driver',
		method: 'get',
		params,
	})
}

// 通过司机ID获取到违章记录
export function getViolationByDriver(params) {
	return request({
		url: '/suf4-asset-service/violate/regulation/summary/by/driver',
		method: 'get',
		params,
	})
}
// 通过车辆ID获取平台渠道
export function getChannelByCar(carId) {
	return request({
		url: `suf4-asset-service/pc/assetCarInfo/platformChannelSelectList?carId=${carId}`,
		method: 'get',
	})
}

// 合同详情
export function contractDetail(id) {
	return request({
		url: `suf4-asset-service/order/info/detail?orderId=${id}`,
		method: 'get',
	})
}

// 合同作废
export function contractInvalid(data) {
	return request({
		url: 'suf4-asset-service/order/info/contract/invalid',
		method: 'post',
		data,
	})
}

// 抵扣列表
export function deductionList(data) {
	return request({
		url: 'suf4-asset-service/order/trade/deduction/page',
		method: 'post',
		data,
	})
}
// 申请抵扣
export function applyDeduction(data) {
	return request({
		url: 'suf4-asset-service/order/trade/bill/deduction/apply',
		method: 'post',
		data,
	})
}

// 导出抵扣
export function exportDeduction(data) {
	return request({
		url: 'suf4-asset-service/order/trade/deduction/export',
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 作废抵扣
export function invalidDeduction(data) {
	return request({
		url: 'suf4-asset-service/order/trade/deduction/invalid',
		method: 'post',
		data,
	})
}

// 根据id获取平台渠道
export function getChannelById(id) {
	return request({
		url: `suf4-user-service/platformChannel/getById/${id}`,
		method: 'get',
	})
}

// 预约还车列表
export function reserveCarList(data) {
	return request({
		url: `suf4-asset-service/order/return/car/reserve/page`,
		method: 'post',
		data,
	})
}
// 续签记录列表
export function renewalList(data) {
	return request({
		url: `/suf4-asset-service/order/info/renewal/page`,
		method: 'post',
		data,
	})
}

// 查看日历
export function reserveCalendar(data) {
	return request({
		url: `suf4-asset-service/order/return/car/reserve/calendar`,
		method: 'post',
		data,
	})
}

// 新增预约还车
export function createReserve(data) {
	return request({
		url: `suf4-asset-service/order/return/car/reserve/add`,
		method: 'post',
		data,
	})
}

// 编辑预约还车
export function editReserve(data) {
	return request({
		url: `/suf4-asset-service/order/return/car/reserve/edit`,
		method: 'post',
		data,
	})
}

// 合同换车
export function changeCar(data) {
	return request({
		url: `/suf4-asset-service/order/info/replace/car`,
		method: 'post',
		data,
	})
}
// 根据合同id获取账单信息
export function getBillByOrderId(orderId) {
	return request({
		url: `/suf4-asset-service/order/trade/bill/summary/by/order?orderId=${orderId}`,
		method: 'get',
	})
}

// 根据车牌号获取预约还车信息
export function getDetailByCarId(carId) {
	return request({
		url: `/suf4-asset-service/order/return/car/reserve/carId/query?carId=${carId}`,
		method: 'get',
	})
}

// 根据车牌号获取司机、司服伙伴等
export function getInfoByCarId(carId) {
	return request({
		url: `/suf4-asset-service/order/info/summary/by/car?carId=${carId}`,
		method: 'get',
	})
}

// 取消预约还车
export function cancelReserve(data) {
	return request({
		url: `/suf4-asset-service/order/return/car/reserve/cancel`,
		method: 'post',
		data,
	})
}

// 通过车辆ID查询七天内的验车单信息
export function checkStockIn(carId) {
	return request({
		url: `/suf4-asset-service/pc/assetCarStockIn/query/with/in/sevenDays?carId=${carId}`,
		method: 'get',
	})
}
// 通过所属公司ID和状态获取公司客户
export function getCompanyById(params) {
	return request({
		url: `/suf4-asset-service/company/customer/info/summary/by/company`,
		method: 'get',
		params,
	})
}

// 根据合同id获取预结算数据
export function getBillByContractId(id, params) {
	return request({
		url: `/suf4-asset-service/settlementBill/getByContractId/${id}`,
		method: 'get',
		params,
	})
}

// 退车结算
export function createSettleBill(data) {
	return request({
		url: `/suf4-asset-service/settlementBill/create`,
		method: 'post',
		data,
	})
}
// 结算单列表
export function settlementBillList(data) {
	return request({
		url: `/suf4-asset-service/settlementBill/paginate`,
		method: 'post',
		data,
	})
}
// 查询单条结算单
export function querySettlementBill(id) {
	return request({
		url: `/suf4-asset-service/settlementBill/getById/${id}`,
		method: 'get',
	})
}
// 导出结算单
export function exportSettlementBill(data) {
	return request({
		url: `/suf4-asset-service/settlementBill/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 复核结算单
export function reviewSettlementBill(data) {
	return request({
		url: `/suf4-asset-service/settlementBill/review`,
		method: 'post',
		data,
	})
}

// 合同延期记录
export function orderDelayList(data) {
	return request({
		url: `/suf4-asset-service/order/delay/page`,
		method: 'post',
		data,
	})
}

// 导出合同延期
export function exportOrderDelay(data) {
	return request({
		url: `/suf4-asset-service/order/delay/export`,
		method: 'post',
		data,
		responseType: 'blob',
	})
}

// 账单金额统计
export function billAmountStat(data) {
	return request({
		url: `/suf4-asset-service/order/trade/bill/amount/statistics`,
		method: 'post',
		data,
	})
}

// 修改手机号
export function updateMobile({ driverId, newMobile }) {
	return request({
		url: `/suf4-asset-service/driver/info/edit/mobile?driverId=${driverId}&newMobile=${newMobile}`,
		method: 'post',
	})
}
