<template>
	<div class="container">
		<label-item title="基础信息" :list="basicData" />
		<label-item title="其它信息" :list="otherData" />
		<label-item title="司机证件" />
		<div v-for="(img, i) in imgList" :key="i" class="item">
			<span class="img-title">{{ img.title }}：</span>

			<div class="img-info-content" v-if="img.list && img.list.length">
				<img @click="show(img.list)" class="image" :src="img.list ? img.list[0] : ''" />
				<p class="amount">{{ img.list.length }}张</p>
			</div>

			<span v-else class="default-text">暂无{{ img.title }}</span>
		</div>
		<el-image-viewer
			v-if="visible"
			:url-list="images"
			hide-on-click-modal
			@close="visible = false"
		/>
		<!-- <v-viewer :images="images" @inited="inited" class="viewer" ref="viewer">
			<template #default="scope">
				<img v-for="src in scope.images" :key="src" :src="src" />
			</template>
		</v-viewer> -->
	</div>
</template>

<script setup>
import dayjs from 'dayjs'
import { ref, defineProps, computed } from 'vue'
// import 'viewerjs/dist/viewer.css'
// import { component as VViewer } from 'v-viewer'
import labelItem from './label'

const _ = defineProps({
	data: {
		type: Object,
		default: () => ({}),
	},
})

const visible = ref(false)
const basicData = computed(() => [
	{ label: '司机姓名', value: _.data.name },
	{ label: '司机状态', value: _.data.statusName },
	{ label: '司机年龄', value: _.data.age },
	{ label: '平台司机ID', value: _.data.driverNo },
	{ label: '司机生日', value: dayjs(_.data.birthday).format('YYYY-MM-DD') },
	{ label: '性别', value: _.data.sexName },
	{ label: '联系电话', value: _.data.mobile },
	{ label: '身份证号', value: _.data.idCard },

	{ label: '紧急联系人', value: _.data.contactPersonName },
	{ label: '紧急联系人手机', value: _.data.contactPersonMobile },
	{ label: '身份证地址', value: _.data.idCardAddress },
	{ label: '现居住地址', value: _.data.presentAddress },
])
const otherData = computed(() => {
	const [start, end] = _.data.drivingLicenseDate || []
	return [
		{
			label: '驾驶证日期',
			value: dayjs(start).format('YYYY-MM-DD') + ' ~ ' + dayjs(end).format('YYYY-MM-DD'),
		},
	]
})
const images = ref([])
const imgList = computed(() => [
	{ title: '身份证', list: [_.data.idCardReversePic, _.data.idCardObversePic] },
	{ title: '驾驶证', list: _.data.drivingLicensePic },
	{ title: '从业资格证', list: _.data.certificateProfessionalPic },
])
// function inited(instance) {
// 	viewer = instance
// }
function show(list) {
	images.value = list
	visible.value = true
}
</script>

<style lang="less" scoped>
.item {
	.flexible(row, flex-start, flex-start);
	margin-bottom: 20px;
	.img-title {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.64);
		width: 100px;
	}

	.default-text {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.64);
	}
	.img-info-content {
		position: relative;
		// width: 100px;
		// height: 100px;
		// margin-left: 10px;
		border-radius: 4px;
		box-shadow: 0 0 0 1px rgba(2229, 229, 229, 0.8);
		cursor: pointer;
		.image {
			height: 100px;
			width: 100px;
			border-radius: 4px;
		}
		.amount {
			position: absolute;
			bottom: -13px;
			left: 0;
			width: 100px;
			height: 20px;
			color: #ffffff;
			text-align: center;
			font-size: 14px;
			z-index: 100;
			background-color: rgba(0, 0, 0, 0.5);
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
		}
	}
}
.viewer {
	display: none;
}
</style>
<style>
.el-overlay {
	z-index: 2014 !important;
}
</style>
