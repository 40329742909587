export const amountReg =
	/(^[1-9]([0-9]{1,8})?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)|^$/
export const phoneReg = /^1[3-9]\d{9}$/
export const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
export const letterAndNumberReg = /^[A-Za-z0-9]+$/
// eslint-disable-next-line no-control-regex
export const chineseReg = /[^\x00-\xff]/
export const nonChineseReg = /^[^\u4e00-\u9fa5]*$/
export const idCardReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
export const carCardReg =
	/^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/
export const orderNoReg = /^[A-Z0-9]{8}\d{9}$/
export const zeroToHundredReg = /^(\d|[1-9]\d|100)(\.\d{1,2})?$/
export const carPriceReg = /(^[0-9]{1,7}?(\.[0-9]{1,2})?$)/
// 纯数字
export const pureNumbersReg = /^[0-9]*$/
// 非0开头的纯数字
export const pureNumbersNotZeroReg = /^[1-9][0-9]*$/
// 不允许空格
export const notSpace = /^[^\s]*$/
// 手机号
export const mobileReg = /^[1][3-9]\d{9}$/
// 0或非0开头纯数字
export const zeroOrPureNumbersNotZeroReg = /^(0|[1-9][0-9]*)$/
// 0或非0开头纯数字和小数点
export const pureNumbersAndDotReg = /^(0|([1-9][0-9]*)|([1-9]{1}[0-9]*\.[0-9]*)|(0\.[0-9]*))$/
// 固话和手机号
export const telephoneAndMobileReg =
	/^([1][3-9]\d{9}|([((]?0[0-9]{2,3}[))]?[-]?)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?)$/
// 微信号 允许手机号当作微信号 6-50位字符
export const wechatReg = /^[a-zA-Z0-9]{1}[-_a-zA-Z0-9]{5,49}$/
// 姓名校验规则 中文、字母和字符·
export const monikerReg = /^[·A-Za-z\u4e00-\u9fa5]+$/
export const numAndAlphaReg = /^[A-Za-z0-9]+$/
